import React, { useEffect, useState, createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import axios from "axios"
import { ONESECOND } from "../utils/constants"

export const isBrowser = () => typeof window !== "undefined"
const DEFAULT_TRACK = {
  node: {
    program: {
      name: "Live Stream",
    },
  },
  original_id: "live-stream",
  url: ONESECOND,
  start: null,
  isLive: true,
}
const LIVE_TRACK = {
  node: {
    program: {
      name: "Live Stream",
    },
  },
  original_id: "live-stream",
  url: "https://streamer.eastsidefm.org/stream",
  start: null,
  isLive: true,
}

const defaultContext = {
  currentTrack: DEFAULT_TRACK,
  sidebar: false,
  mobileSidebar: false,
}

export const StationContext = createContext(defaultContext)

export const StationProvider = ({ children }) => {
  // const { allProgram, allStation } = useStaticQuery(
  //   graphql`
  //     query {
  //       allStation {
  //         edges {
  //           node {
  //             ...Station
  //           }
  //         }
  //       }
  //     }
  //   `
  // // )
  // const station =
  //   allStation && allStation.edges && allStation.edges[0]
  //     ? allStation.edges[0].node
  //     : null
  // const foundPrograms =
  //   allProgram && allProgram.edges && allProgram.edges ? allProgram.edges : null
  const station = []
  const foundPrograms = []

  const [initialLoad, setInitialLoad] = useState(false)
  const [initialSave, setInitialSave] = useState(false)
  const [playlist, setPlaylist] = useState([])
  const [sidebar, setSidebar] = useState(false)
  const [mobileSidebar, setMobileSidebar] = useState(false)
  const [programs, setPrograms] = useState([])
  const [episodes, setEpisodes] = useState([])
  const [onAir, setOnAir] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [streamProgress, setStreamProgress] = useState(0)
  const [streamPercent, setStreamPercent] = useState(0)
  const [duration, setDuration] = useState(0)
  const [durationSeconds, setDurationSeconds] = useState(0)
  const [length, setLength] = useState(0)
  const [activeIndex, setActiveIndex] = useState(null)
  const [player, setPlayer] = useState(null)
  const [currentTrack, setCurrentTrack] = useState(DEFAULT_TRACK)
  // const ref = React.useRef();

  useEffect(() => {}, [playlist])

  let ref = player => {
    setPlayer(player)
  }

  const setLiveStream = async () => {
    setCurrentTrack(LIVE_TRACK)
    setIsPlaying(true)
  }

  const removeItemById = async id => {
    const leftOver = playlist.filter(item => item.node.original_id !== id)
    setPlaylist(leftOver)
  }

  const addTrackToPlaylist = async track => {
    // console.log(track)
    const test = await playlist.filter(
      item => item.node.original_id === track.node.original_id
    )

    if (test.length === 0) {
      setPlaylist([...playlist, track])
    }
  }

  useEffect(() => {
    const getOnAir = async () => {
      let req = await axios.get(
        `https://app.myradio.click/api/public/station/${process.env.GATSBY_STATION_SLUG}/latest`
      )

      if (req) {
        setOnAir(req.data.data)
      }
    }
    // getOnAir()
    if (initialSave === false) {
      setInitialSave(true)
    } else {
      localStorage.setItem("episodes", JSON.stringify(playlist))
      setSidebar(true)
    }
  }, [playlist])

  const updateTime = event => {
    var bcr = event.target.getBoundingClientRect()
    if (currentTrack === null) {
      return
    }

    let percent = (event.clientX - bcr.left) / bcr.width
    // console.log(percent)
    player.seekTo(percent)
  }

  useEffect(() => {
    // console.log("index updated", activeIndex, playlist)
    if (activeIndex !== null) {
      // setCurrentTrack(null)
      // console.log("SEtting active track", playlist[activeIndex])
      setCurrentTrack(playlist[activeIndex])
    }
  }, [activeIndex])

  useEffect(() => {
    // console.log(
    //   "Changed track",
    //   currentTrack,
    //   initialLoad !== null ? "yeah" : "no"
    // )
    if (initialLoad === true) {
      setIsPlaying(true)
    } else {
      setInitialLoad(true)
    }
  }, [currentTrack])

  const seek = no => {
    let current = player.getCurrentTime()
    player.seekTo(current + no)
  }

  //   const rawSetStations = (_stations) => {
  //     if (_stations !== null) {
  //       localStorage.setItem("stations", JSON.stringify(_stations));
  //     }
  //   };

  //   useEffect(
  //     (_) => {
  //       rawSetStations(stations);
  //     },
  //     [stations]
  //   );

  // const initiliazePlaylist = async () => {
  //   const _searchFormData =
  //     isBrowser() && (window.localStorage.getItem("searchFormData"))
  //       ? window.localStorage.getItem("searchFormData")
  //       : null;

  //   if (typeof _searchFormData === "string") {
  //     setSearchFormData(JSON.parse(_searchFormData));
  //     return JSON.parse(_searchFormData);
  //   }

  //   setSearchFormData(null);

  //   return null;
  // };

  //     initiliazePlaylist();
  //   }, []);

  //   useMemo(() => {
  //     if (!isBrowser()) return
  //     if (!reviewStationFormData) return

  //     localStorage.setItem(
  //       "EnquireFormReviewStations",
  //       JSON.stringify(reviewStationFormData)
  //     )
  //   }, [reviewStationFormData])

  useEffect(() => {
    const updateTracks = async () => {
      // console.log("loading out episodes")
      const storedPrefs = isBrowser()
        ? await localStorage.getItem("episodes")
        : []

      // console.log("Blah", storedPrefs)
      if (typeof storedPrefs === "string") {
        setPlaylist(JSON.parse(storedPrefs))
        return JSON.parse(storedPrefs)
      }
      setPlaylist([])
      return []
    }
    updateTracks()
  }, [])

  const removeFromPlaylist = async item => {
    const after = playlist.filter(t => t.url !== item.url)
    setPlaylist(after)
  }

  return (
    <StationContext.Provider
      value={{
        setLiveStream,
        mobileSidebar,
        setMobileSidebar,
        removeItemById,
        playlist,
        setPlaylist,
        programs,
        setPrograms,
        removeFromPlaylist,
        episodes,
        setEpisodes,
        sidebar,
        setSidebar,
        isPlaying,
        setIsPlaying,
        streamProgress,
        setStreamProgress,
        streamPercent,
        setStreamPercent,
        duration,
        setDuration,
        length,
        setLength,
        activeIndex,
        setActiveIndex,
        durationSeconds,
        setDurationSeconds,
        onAir,
        ref,
        seek,
        addTrackToPlaylist,
        currentTrack,
        setCurrentTrack,
        // foundPrograms,
        station,
        updateTime,
        LIVE_TRACK,
      }}
    >
      {children}
    </StationContext.Provider>
  )
}
