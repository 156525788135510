// custom typefaces
import React from "react"
import "./src/inc/fragments"
import { StationProvider } from "./src/context/station-context"
import AudioPlayer from "./src/components/audio-player"
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/global.css"
import "slick-carousel/slick/slick.css"
// custom CSS styles
import "./src/css/style.css"
import { ThemeProvider } from "./src/context/theme-context"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <StationProvider>
      <div>{element}</div>
    </StationProvider>
  </ThemeProvider>
)

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <div {...props}>
      {element}
      <div>
        <AudioPlayer />
      </div>
    </div>
  )
}
